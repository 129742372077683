<template>
  <div class="numberNavBarIcon">
    <div class="items" ref="items">
        <span v-if="itemNumberShow">{{itemNumber}}</span>
        <div class="svgBox" v-if="!itemNumberShow">
            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.4772 6.98854C31.1741 6.99758 30.8864 7.12425 30.6751 7.34173L12.8333 25.1835L5.49154 17.8417C5.38404 17.7298 5.25527 17.6404 5.11279 17.5788C4.97031 17.5172 4.81697 17.4847 4.66175 17.4831C4.50654 17.4815 4.35257 17.5109 4.20886 17.5696C4.06516 17.6283 3.9346 17.715 3.82484 17.8248C3.71509 17.9345 3.62833 18.0651 3.56966 18.2088C3.51099 18.3525 3.48158 18.5065 3.48316 18.6617C3.48474 18.8169 3.51727 18.9702 3.57886 19.1127C3.64044 19.2552 3.72983 19.384 3.8418 19.4915L12.0085 27.6581C12.2273 27.8769 12.524 27.9997 12.8333 27.9997C13.1427 27.9997 13.4394 27.8769 13.6582 27.6581L32.3249 8.99147C32.4935 8.8276 32.6086 8.61662 32.6552 8.38618C32.7018 8.15574 32.6778 7.91659 32.5861 7.70008C32.4945 7.48356 32.3396 7.29977 32.1417 7.17281C31.9438 7.04584 31.7122 6.98161 31.4772 6.98854Z" fill="white"/>
            </svg>
        </div>
    </div>
    <div class="text1" ref="text1">
        <slot name="text1"></slot>
    </div>
    <div class="text2" ref="text2">
        <slot name="text2"></slot>
    </div>
    <div class="centerLine" v-show="lineBoolen">
        <div class="line" ref="line"/>
    </div>
  </div>
</template>

<script>
export default {
  name:'NumberNavBarIcon',
  components: {

  },
  props:{
      itemNumber:{
          type:Number,
      },
      page:{
          type:Number,
          default: 0
      },
      allPage:{
          type:Number,
      }
  },
  data() {
    return {
        lineBoolen:"",
        itemNumberShow:true
    }
  },
  methods:{
        itemsFristStyle(){
            this.$refs.items.style.marginTop = "9px"
            this.itemsStyle()
        },
        itemsStyle(){
            if(this.itemNumber == this.page){
                this.$refs.items.style.marginTop = "0px"
                this.$refs.items.style.width = "75px"
                this.$refs.items.style.height = "75px"
                this.$refs.items.style.lineHeight = "75px"
                this.$refs.items.style.backgroundColor = "#FF1057"
                this.$refs.items.style.color = "#FFFFFF"
                this.$refs.text1.style.fontSize = "16px"
                this.$refs.items.style.marginBottom = "0px"
                this.$refs.text1.style.marginBottom = "0px"
                this.$refs.text2.style.fontSize = "12px"
                this.$refs.items.style.fontSize = "25px"
            }
            else{
                this.$refs.items.style.marginTop = "9px"
                this.$refs.items.style.width = "58px"
                this.$refs.items.style.height = "58px"
                this.$refs.items.style.lineHeight = "58px"
                this.$refs.items.style.backgroundColor = "#EEEFF4"
                this.$refs.items.style.color = "#000000"
                this.$refs.text1.style.fontSize = "10px"
                this.$refs.items.style.marginBottom = "10px"
                this.$refs.text1.style.marginBottom = "5px"
                this.$refs.text2.style.fontSize = "8px"
                this.$refs.items.style.fontSize = "20px"
            }

            
        },
        lineShow(){
            if(this.allPage == this.itemNumber){
                this.lineBoolen = false
            }
            else{
                this.lineBoolen = true
            }
        },
        lineStyle(){
            if(this.page > this.itemNumber){
                this.$refs.line.style = "border: 1px #3C7AD9 solid;"
                this.$refs.items.style.backgroundColor = "#3C7AD9"
                this.itemNumberShow = false
            }
            else{
                this.$refs.line.style = "border: 1px rgb(158, 158, 158) solid;"
                this.itemNumberShow = true
            }
        }
  },
  mounted(){
      this.itemsFristStyle()
      this.lineShow()
  },
  watch:{
      "page"(){
          this.itemsStyle()
          this.lineStyle()
      }
  }
}
</script>

<style scoped>
.numberNavBarIcon{
    position: relative;
    margin: 0 auto;
    height: 100%;
}
.centerLine{
    position: absolute;
    top: 0;
    height: 100%;
    left: 50%;
}
.line{
    position: absolute;
    top: 32%;
    left: 45px;
    width: 90px;
    border: 1px rgb(158, 158, 158) solid;
}
.items{
    margin: 0 auto;
    width: 58px;
    height: 58px;
    background-color: #EEEFF4;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 58px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 58px;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    transition: .2s;
}
.text1{
    margin: 0 auto;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 5px;
    text-align: center;
    transition: .2s;
}
.text2{
    margin: 0 auto;
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: #959595;
    transition: .2s;
}
.svgBox{
    padding-top: 10px;
}
</style>
