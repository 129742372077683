<template>
  <div class="leftBar">
    <div id="img2" ref="img2">
        <img src="../../../../../assets/xuImg/Vector3.png" alt="">
    </div>
    <div id="img3" ref="img3">
        <img src="../../../../../assets/xuImg/Vector2.png" alt="">
    </div>
    <div id="img1">
        <svg width="90" height="960" viewBox="0 0 90 960" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="90" height="960" fill="#FF1057"/>
        </svg>
    </div>
    <div id="img4" ref="img4">
        <img src="../../../../../assets/xuImg/Vector1.png" alt="">
    </div>
    <div class="spanBox">
        <slot name="spanBox"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name:'LeftBar',
  components: {

  },
  props:{
      page:{
          type:Number,
          default: 0
      }
  },
  data() {
    return {
        switch:false
    }
  },
  methods:{
      MoveContrl1(){
          this.$refs.img2.style = "top: 25%;"
          this.$refs.img3.style = "top: -90%;"
          this.$refs.img4.style = "top: -5%;"
      },
      MoveContrl2(){
          this.$refs.img2.style = "top: -25%;"
          this.$refs.img3.style = "top: -40%;"
          this.$refs.img4.style = "top: -10%;"
      }
  },
  watch:{
      "page"(){
          this.MoveContrl1()
          setTimeout(this.MoveContrl2,500)
      }
  }
}
</script>

<style scoped>
.leftBar{
    width: 100%;
    height: 100%;
}
#img1{
    position: absolute;
    left: 0;
    top: 0;
    transition: .5s;
}
#img2{
    position: absolute;
    left: 40px;
    top: -25%;
    transition: .5s;
}
#img3{
    position: absolute;
    left: 110px;
    top: -40%;
    transition: .5s;
}
#img4{
    position: absolute;
    left: 90px;
    top: -10%;
    transition: .5s;
}
.spanBox{
    position: absolute;
    width: 100%;
    height: 100%;
}
</style>
