<template>
  <div class="drowDownMeun">
    <div class="tittleBox" @click="tittleBoxMove" ref="tittleBox">
      <div class="tittle">
        <div class="leftItem" ref="leftItem">
          <span>{{titleName}}</span>
        </div>
        <div :class="svgClass">
          <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9393 13.0607C11.5251 13.6464 12.4749 13.6464 13.0607 13.0607L22.6066 3.51472C23.1924 2.92893 23.1924 1.97919 22.6066 1.3934C22.0208 0.807611 21.0711 0.807611 20.4853 1.3934L12 9.87868L3.51472 1.3934C2.92893 0.807611 1.97919 0.807611 1.3934 1.3934C0.807611 1.97919 0.807611 2.92893 1.3934 3.51472L10.9393 13.0607ZM10.5 11V12H13.5V11H10.5Z" :fill="color"/>
          </svg>
        </div>
      </div>
    </div>
    <div class="meunBox" ref="meunBox">
      <div class="meun">
        <slot name="meun"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'DrowDownMeun',
  components: {

  },
  props:{
    meunHeight:{
      type:Number,
    },
    titleName:{
      type:String,
    },
    down:{
      type:Boolean,
      default:true,
    },
    backgroundColor:{
      type:String,
      default:"#EEEFF4",
    },
    color:{
      type:String,
      default:"#000",
    }
  },
  data() {
    return {
      upDown:true,
      svgClass:"rightItem1",
    }
  },
  methods:{
    tittleBoxMove(){
      this.upDown = !this.upDown
    },
    drowDown(){
      if(this.upDown == true){
        this.svgClass = "rightItem1"
        this.$refs.meunBox.style.height = this.meunHeight +"px"
      }
      else{
        this.svgClass = "rightItem2"
        this.$refs.meunBox.style.height = "0px"
      }
    },
    porpsItem(){
      if(this.down == true){
        this.upDown = true
      }
      else{
        this.upDown = false
      }
    },
    tittleBoxStyle(){
      this.$refs.tittleBox.style.backgroundColor = this.backgroundColor
      this.$refs.leftItem.style.color = this.color
      
    }
  },
  mounted(){
    this.drowDown()
    this.porpsItem()
    this.tittleBoxStyle()
  },
  watch:{
    "upDown"(){
      this.drowDown()
      this.$emit('upDown', this.upDown)
    },
    "down"(){
      this.porpsItem()
    }
  }
}
</script>

<style scoped>
.drowDownMeun{
  width: 100%;
}
.tittleBox{
  width: 100%;
  height: 37px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
}
.tittle{
  height: 100%;
  margin: 0 auto;
  /* border: 1px red solid; */
  width: 90%;
  position: relative;
}
.leftItem{
  position: absolute;
  left: 0;
  top: 0;
  line-height: 37px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.rightItem1{
  position: absolute;
  right: 0;
  top: 10%;
  transition: .3s;
  -moz-transform:rotate(180deg);
  -webkit-transform:rotate(180deg);
  -o-transform:rotate(180deg);
  -ms-transform:rotate(180deg);
  transform:rotate(180deg);
}
.rightItem2{
  position: absolute;
  right: 0;
  top: 20%;
  transition: .3s;
  
}
.meunBox{
  width: 100%;
  /* border: 1px rgb(0, 255, 13) solid; */
  overflow: hidden;
  transition: .3s;
  position: relative;
}
.meun{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  /* border: 1px rgb(129, 95, 255) solid; */
}

</style>
