<template>
  <div class="elUploadBtnStyle">
    <div class="imgBox">
        <slot name="img"></slot>
    </div>
    <div class="mask"></div>
    <div class="iconBox">
      <div class="center">
        <div class="svgBox">
          <svg width="73" height="71" viewBox="0 0 73 71" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.73332 11.8333C7.04449 11.8333 4.86665 13.9514 4.86665 16.5666V54.4332C4.86665 57.0484 7.04449 59.1666 9.73332 59.1666H63.2666C65.9555 59.1666 68.1333 57.0484 68.1333 54.4332V16.5666C68.1333 13.9514 65.9555 11.8333 63.2666 11.8333H9.73332ZM55.9666 18.9333C58.6555 18.9333 60.8333 21.0514 60.8333 23.6666C60.8333 26.2817 58.6555 28.3999 55.9666 28.3999C53.2778 28.3999 51.1 26.2817 51.1 23.6666C51.1 21.0514 53.2778 18.9333 55.9666 18.9333ZM21.9 28.4045C23.4068 28.4045 24.9135 28.9642 26.0633 30.0825L34.0001 37.8019L36.5 40.2332L39.3135 42.9697C40.3404 43.9684 41.9984 43.9592 43.0253 42.9605C44.0546 41.9617 44.0546 40.3376 43.0253 39.3365L40.2213 36.6139L42.07 34.8158C44.3695 32.5793 48.0971 32.5793 50.3966 34.8158L60.8333 44.9666V52.0666H12.1667V35.4999L17.7367 30.0825C18.8865 28.9642 20.3931 28.4045 21.9 28.4045Z" fill="white"/>
          </svg>
        </div>
        <div class="spanBox">
          <span>UpLoad File</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'ElUploadBtnStyle',
  components: {

  },
  data() {
    return {

    }
  },
  methods:{},
}
</script>

<style scoped>
.elUploadBtnStyle{
  width: 246px;
  height: 138px;
  cursor: pointer;
  position: relative;
}
.imgBox{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(24, 25, 31, 0.5);
}
.iconBox{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}
.center{
  margin: 0 auto;
  height: 100%;
}
.svgBox{
  padding-top: 20px;
}
.spanBox{
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: rgb(255, 255, 255);
}
</style>
