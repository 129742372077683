<template>
  <div class="numberNavBar">
    <div class="centerBox">
      <div class="itemBox" v-for="item in allPage" :key="item" >
        <NumberNavBarIcon :itemNumber="item" :page="page" :allPage="allPage">
          <template v-slot:text1>
            <slot :name="item"></slot>
          </template>
          <template v-slot:text2>
            <slot :name="item + allPage"></slot>
          </template>
        </NumberNavBarIcon>
      </div>
    </div>
  </div>
</template>

<script>
import NumberNavBarIcon from '@/views/login/joinUs/components/numberNavBar/NumberNavBarIcon'
export default {
  name:'NumberNavBar',
  components: {
      NumberNavBarIcon
  },
  props:{
      page:{
        type:Number,
        default: 0
      },
      allPage:{
        type:Number,
      },
      startPage:{
        type:Number,
        default: 1
      }
  },
  data() {
    return {
      text1:"text1",
      text2:"text2",
    }
  },
  methods:{

  },
  watch:{

  }
}
</script>

<style scoped>
.numberNavBar{
    width: 100%;
    height: 100%;
    display: flex;
}
.centerBox{
    margin: 0 auto;
    height: 100%;
    display: flex;
}
.itemBox{
    width: 181px;
    height: 100%;
    display: flex;
}
</style>
