import request from "../request"

export function getDictById(id) {
  return request({
    url: '/api/crm/dict/listByParentId/'+ id,
    method: 'get',
  })
}
export function smsCountryList() {
  return request({
    url: "/api/user/ucenter/sms-country/smsCountryList",
    method: 'get',
  })
}
export function CountryList() {
  return request({
    url: "/api/user/ucenter/country/list",
    method: 'get',
  })
}
export function CityList(id) {
  return request({
    url: "/api/user/ucenter/city/cityList/"+ id,
    method: 'get',
  })
}
export function ProveImageController(borkerForm) {
  return request({
    url: '/api/crm/prove-image/save-prove-img',
    method: 'post',
    data: borkerForm
  })
}
export function BorkerPost(borkerForm) {
  return request({
    url: '/api/user/ucenter/broker/openAccount',
    method: 'post',
    data: borkerForm
  })
}
export function BorkerPosts(borkersForm) {
  return request({
    url: '/api/crm/brokers/apply-brokers',
    method: 'post',
    data: borkersForm
  })
}
export function AgencyPost(AgencyForm) {
  return request({
    url: '/api/crm/agent/apply-agent',
    method: 'post',
    data: AgencyForm
  })
}
export function OpenAccountPost(OpenAccountForm) {
  return request({
    url: '/api/user/ucenter/finger-account/openAccount',
    method: 'post',
    data: OpenAccountForm
  })
}